<template>
  <div class="tb-container2" ref="tbContainerRef">
    <!-- 左边的 el-select 元素 -->

    <!-- 右边的元素，使用 flex 容器来包裹它们 -->
    <div
      style="display: flex; justify-content: flex-end; align-items: center"
      v-if="!isViewPage"
    >
      <!-- 条件渲染的 svg-icon -->

      <!-- 条件渲染的 el-input -->
      <el-input
        v-model="inputValue"
        class="w-50 m-2"
        placeholder="Search Parameter"
        style="width: 20%"
        ><i><Search /></i
      ></el-input>
    </div>
    <!-- 表格 -->
    <el-table
      ref="tableRef"
      :data="displayList"
      style="width: 100%; margin-top: 5px"
      @selection-change="handleSelectionChange"
      :max-height="maxHeight"
      border
      stripe
    >
      <el-table-column
        v-if="parameterList.length > 0"
        type="index"
        label="Seq"
        :width="50"
      />
      <!-- <el-table-column prop="id" label="ID" width="100" /> -->
      <!-- TemplateType Template -->

      <el-table-column prop="key" label="Parameter" width="450" />

      <el-table-column prop="length" label="Value Length" width="160">
        <template #default="{ row }">
          {{ row?.value?.length }}
        </template>
      </el-table-column>
      <el-table-column prop="value" label="Value">
        <template #default="{ row }">
          <!-- 区分templateType 区分类型 -->
          <!--字符串 String-->

          <!-- 字符串转成数字类型 -->
          <div v-if="!row.isEditable">
            <span>{{ row?.value }}</span>
            <i
              v-if="!isViewPage"
              style="color: #1a6eb6; cursor: pointer"
              class="el-icon-edit-outline"
              @click="handleEdit(row)"
            ></i>
          </div>
          <el-form :model="row" ref="formRef" inline-message v-if="!isViewPage">
            <el-form-item prop="value" :rules="getCurrentRule(row.type)">
              <el-input
                style="width: 65%"
                ref="inputRef"
                v-show="row.isEditable"
                v-model="row.value"
                @blur="row.isEditable = false"
                :disabled="row.disabled"
                maxlength="255"
              >
              </el-input>
            </el-form-item>
          </el-form>

          <!--Boolean-->
        </template>
      </el-table-column>

      <!-- Card -->
    </el-table>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  defineExpose,
  nextTick,
  watch,
  computed
} from 'vue'
// import { useStore } from 'vuex'
import { Search } from '@element-plus/icons'
import { useCommonParam } from '@/hooks/use-common-params.js'

const props = defineProps({
  templateType: {
    type: String,
    default: '00' // Parameter
  },
  listDataOptions: {
    type: Array,
    default: () => []
  },
  isViewPage: {
    type: Boolean,
    default: false
  },
  isShowCheckItem: {
    type: Boolean,
    default: true
  },
  maxHeight: {
    type: Number,
    default: 800
  }
})

const tableRef = ref(null)
// const store = useStore()
const parameterList = ref([])
const selectedRowItems = ref([])

const displayList = computed(() => {
  return parameterList.value.filter(
    (data) =>
      !inputValue.value ||
      data.key.includes(inputValue.value) ||
      data.key.toLowerCase().includes(inputValue.value.toLowerCase())
  )
})
// const selectedRowKeys = ref([])
const getCurrentRule = (type) => {
  if (type === '01') {
    return [
      {
        required: true,
        message: 'Please input an integer value',
        trigger: 'blur',
        // 假设你的校验库支持这种类型检查
        pattern: /^[-+]?\d+$/ // 正则表达式，用于匹配整数
      }
    ]
  } else if (type === '03') {
    return [
      {
        pattern: /^(true|false)$/,
        message: 'Please input true or false',
        trigger: 'blur'
      }
    ]
  } else if (type === '02') {
    return [
      {
        required: true,
        message: 'Please input a float value',
        trigger: 'blur',
        pattern: /^[-+]?\d+(\.\d+)?$/ // 正则表达式，用于匹配浮点数
      }
    ]
  }
}

const inputRef = ref(null)
const handleEdit = (row) => {
  parameterList.value = parameterList.value.map((item) => {
    if (item.key === row.key) {
      item.isEditable = true
    }
    return item
  })
  nextTick(() => {
    inputRef.value.focus()
  })
}

const formRef = ref(null)

const getValidateRes = () => {
  formRef.value.validate((valid) => {
    return valid
  })
}

const handleSelectionChange = (val) => {
  selectedRowItems.value = val
  console.log(val)
  // 重置选中状态

  listData.value = listData.value.map((item) => {
    for (let i = 0; i < val.length; i++) {
      // 重置选中状态
      if (item?.checked) item.checked = false
      if (item.key === val[i].key) {
        return {
          ...item,
          checked: true
        }
      }
    }
    return item
  })
}

watch(
  () => selectedRowItems.value.length,
  (newVal, oldVal) => {
    if (newVal === 0 && oldVal > 0) {
      listData.value.forEach((item) => {
        if (item.checked) {
          item.checked = false
        }
      })
    }
  }
)

const inputValue = ref('')
watch(
  () => inputValue.value,
  async (newVal, oldVal) => {
    if (newVal === '' && oldVal !== '') {
      // 保存当前选中的行
      const checkedItems = listData.value
        ?.filter((item) => item.checked)
        ?.map((item) => item.key)
      console.log(checkedItems)
      nextTick(() => {
        for (let i = 0; i < parameterList.value.length; i++) {
          if (checkedItems.includes(parameterList.value[i].key)) {
            tableRef.value.toggleRowSelection(parameterList.value[i], true)
          }
        }
      })
    }
  }
)

watch(
  () => props.listDataOptions,
  (newVal) => {
    if (newVal) {
      parameterList.value = newVal
      console.log(parameterList.value)
      // loadCheckedItems()
    }
  }
)

const listData = ref([])

// const loadCheckedItems = () => {
//   // selectedRowKeys.value = props.listDataOptions
//   if (
//     Array.isArray(props.listDataOptions) &&
//     props.listDataOptions.length > 0 &&
//     parameterList.value.length > 0
//   ) {
//     props.listDataOptions.forEach((key) => {
//       const item = parameterList.value.find((param) => {
//         console.log(key, param.key)
//         return param.key === key
//       })

//       if (item) {
//         nextTick(() => {
//           tableRef.value.toggleRowSelection(item, true)
//         })
//       }
//     })
//   }
// }

const valueTypes = ref([])
const valueTypeMap = ref({})
const cardTypeMap = ref({})
const cardTypes = ref([])
const initialListData = async () => {
  parameterList.value = props.listDataOptions
  console.log(parameterList.value)
  parameterList.value = parameterList.value.map((item) => ({
    ...item,
    isEditable: false
  }))
  listData.value = JSON.parse(JSON.stringify(parameterList.value))
}
onMounted(async () => {
  initialListData(props.templateType)
  // loadCheckedItems()
  valueTypes.value = await useCommonParam('value_type')
  valueTypes.value.forEach((item) => {
    valueTypeMap.value[item.value] = item.key
  })

  cardTypes.value = await useCommonParam('card_type')
  cardTypes.value.forEach((item) => {
    cardTypeMap.value[item.value] = item.key
  })
})

watch(
  () => props.templateType,
  async (newVal) => {
    if (newVal) {
      await initialListData(newVal)
      // loadCheckedItems()
    }
  }
)
const selectedListData = ref([])
watch(
  () => listData.value,
  (newVal) => {
    if (newVal) {
      console.log(newVal)
      selectedListData.value = newVal.filter((item) => {
        if (item.checked) {
          return item.key
        }
      })
      console.log(selectedListData.value)
    }
  },
  { deep: true }
)

defineExpose({
  selectedRowItems,
  getValidateRes,
  selectedListData,
  parameterList
})
</script>

<style>
/* .tb-container2 .el-table .el-table__cell {
  padding: 0 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
} */
</style>
