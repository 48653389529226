import {
  getCommonParamsOptions,
  getParamsOptionsAsync,
  getGlobalParamsOptionsAsync
} from '@/utils/common.js'

import Validator from '@/validators'
// import { isIP } from '@/utils/validate'

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'terminalId',
      type: 'input',
      label: 'terminal.id',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'sn',
      type: 'input',
      label: 'terminal.sn',
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'host',
    //   type: 'input',
    //   label: 'template.host'
    // },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'user.tenant-id',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getParamsOptionsAsync('tenantList'),
      isLinkageOptions: true,
      otherOptions: {
        isSingle: true
      }
    },
    {
      field: 'merchantId',
      type: 'select',
      label: 'merchant.merchant-name',
      options: []
    },
    {
      field: 'modelId',
      type: 'select',
      label: 'file.model-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalModel', {
          isNeedPreFix: false
        })
    },
    {
      field: 'batchType',
      type: 'select',
      label: 'param.batch',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('batch_type')
    },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'general.status',
    //   options: [],
    //   isResolveGlobalParams: true,
    //   handler: () => getCommonParamsOptions('status')
    // },
    // {
    //   field: 'customerId',
    //   type: 'select',
    //   label: 'customer.customer-name',
    //   options: [],
    //   isResolveGlobalParams: true,
    //   handler: () =>
    //     getGlobalParamsOptionsAsync('customerList', {
    //       labelParams2: 'customerId'
    //     })
    // },

    // {
    //   field: 'version',
    //   type: 'input',
    //   label: 'general.version'
    // },
    // {
    //   field: 'ip',
    //   type: 'input',
    //   label: 'terminal.ip'
    // },
    // {
    //   field: 'port',
    //   type: 'input',
    //   label: 'terminal.port',
    //   otherOptions: {
    //     maxlength: 5
    //   }
    // },
    {
      field: 'remark',
      type: 'textarea',
      label: 'general.remark',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    terminalId: [
      // {
      //   required: true,
      //   message: 'general.required',
      //   trigger: 'change'
      // },
      { validator: Validator.minLength, length: 5 },
      { validator: Validator.maxLength, length: 20 }
    ],
    sn: [
      // {
      //   required: true,
      //   message: 'general.required',
      //   trigger: 'change'
      // },
      { validator: Validator.minLength, length: 2 },
      { validator: Validator.maxLength, length: 50 }
    ],
    status: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    batchType: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    // merchantId: [
    //   {
    //     required: true,
    //     message: 'general.required',
    //     trigger: 'change'
    //   }
    // ],
    // customerId: [
    //   {
    //     required: true,
    //     message: 'general.required',
    //     trigger: 'change'
    //   }
    // ],
    modelId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
    // host: [{ validator: isIP, trigger: 'change' }]
    // version: [
    //   {
    //     required: true,
    //     message: 'general.required',
    //     trigger: 'change'
    //   },
    //   { validator: Validator.minLength, length: 1 },
    //   { validator: Validator.maxLength, length: 50 }
    // ],
    // ip: [
    //   { validator: Validator.minLength, length: 8 },
    //   { validator: Validator.maxLength, length: 16 }
    // ],
    // port: [
    //   { validator: Validator.minLength, length: 1 },
    //   { validator: Validator.maxLength, length: 5 }
    // ]
  }
}
