<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
      <template #customForm v-if="['view', 'edit'].includes(currentPageType)">
        <el-col :span="24" style="padding: 18px">
          <!-- <el-form>
            <el-form-item label="Parameter" required>
              <el-select
                style="width: 100%"
                v-model="selectedItems"
                placeholder=" "
                disabled
                multiple
              >
                <el-option
                  v-for="item in listData"
                  :key="item.id"
                  :label="item.key"
                  :value="item.key"
                />
              </el-select>
            </el-form-item>
          </el-form> -->
          <LTable
            ref="parameterList"
            :listDataOptions="currentParameterData"
            :templateType="templateType"
            :isShowCheckItem="false"
            :isViewPage="currentPageType === 'view'"
          ></LTable>
        </el-col>
      </template>
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import {
  baseNewFormConfig,
  baseUpdateFormConfig,
  baseViewFormConfig
} from './config/index'
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  onUnmounted,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { findCurrentSelectedTreeNode } from '@/utils/util'
import LTable from './dynamic-table2.vue'
import { getParamsOptions } from '@/utils/common'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'TerminalView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('general.router-terminal-view'),
    pageType: 'view'
  },
  {
    routesName: 'TerminalEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('general.router-terminal-edit'),
    pageType: 'edit',
    isResetFields: false
  },
  {
    routesName: 'TerminalNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-terminal-new'),
    pageType: 'add'
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()

// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig
const currentPageType = ref(pageType)

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})

currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const bus = appContext.config.globalProperties.$bus

const baseFormRef = ref('')
const initMerchantOptions = async (form) => {
  const merchantIdItem = currentPageConfig.baseFormConfig.value.formItems.find(
    (item) => item.field === 'merchantId'
  )
  const merchantOption = await store.dispatch(
    'user/getMerchantListByTenantId',
    {
      tenantId: form?.tenantId
    }
  )
  // option更新时同时清空merchant数据
  baseFormRef.value.formData.merchantId = ''
  if (form.tenantId) {
    merchantIdItem.options = merchantOption?.map((item) => {
      return {
        value: item.id,
        label: item.userName
      }
    })
  } else {
    merchantIdItem.options = []
  }
}
const initModelOption = async (form) => {
  console.log(form)
  const modelIdItem = currentPageConfig.baseFormConfig.value.formItems.find(
    (item) => item.field === 'modelId'
  )
  if (form?.tenantId === undefined || !form?.tenantId) form.tenantId = ''
  const modelOption = await store.dispatch(
    'globalParams/getTerminalModelListByTenantId',
    form?.tenantId
  )
  modelIdItem.options = modelOption?.map((item) => {
    return {
      value: item.id,
      label: item.name
    }
  })
}

const handleChange = async (form) => {
  console.log(form)
  const { fieldName } = form
  if (fieldName === 'tenantId') {
    await initMerchantOptions(form)
    await initModelOption(form)
  }
}

const listData = ref([])

const getParameterList = async (type) => {
  listData.value = await store.dispatch('param/getAllParameters', type)
}

const tenantList = getParamsOptions('tenantList')
onMounted(async () => {
  // 查询ParameterList
  await getParameterList('00')
  bus.on('linkageOptionsChange', handleChange)
})

const parameterList = ref(null)
const selectedItems = ref([])
watch(
  () => parameterList?.value?.selectedListData,
  (newVal) => {
    if (newVal) {
      selectedItems.value = newVal?.map((item) => item.key)
    }
  }
)

onUnmounted(() => {
  // 组件销毁前注销事件监听
  bus.off('linkageOptionsChange', handleChange)
})

const currentParameterData = ref([])

// 5、定义方法获取当前页面的初始化数据

const getCurrentPageInfo = async () => {
  const terminalInfo = await store.dispatch('terminal/handleGetTerminal', {
    id
  })
  if (terminalInfo?.parameter) {
    console.log(terminalInfo.parameter)
    currentParameterData.value = Object.keys(
      JSON.parse(terminalInfo?.parameter)
    )?.map((key) => {
      return {
        key,
        value: JSON.parse(terminalInfo?.parameter)[key]
      }
    })
  }
  selectedItems.value = currentParameterData.value
  // 检查 terminalInfo 是否包含有效数据（非空对象）
  if (terminalInfo && Object.keys(terminalInfo).length > 0) {
    // 尝试并行化 initMerchantOptions 和 initModelOption
    const merchantPromise = initMerchantOptions(terminalInfo)
    const modelPromise = initModelOption(terminalInfo)
    await Promise.all([merchantPromise, modelPromise])

    // 在这里处理 tenantId 和其他可能的属性
    if (terminalInfo.tenantId) {
      terminalInfo.tenantId = findCurrentSelectedTreeNode(
        tenantList,
        terminalInfo.tenantId,
        'id'
      )
    }
    pageInfo.value = terminalInfo
  }
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  const form = { ...data }
  form.tenantId = form.tenantId
    ? findCurrentSelectedTreeNode(tenantList, form.tenantId, 'name')
    : ''
  let url = 'handleTerminalAction'
  if (pageType === 'edit') {
    form.id = id
    url = 'handleUpdateTerminal'
  }
  handleMessageCommit(`terminal/${url}`, form).then(() => {
    router.push('/terminal/overview')
  })
}
</script>

<style scoped></style>
